



import Vue from 'vue'
export default Vue.extend({
	props: ['src'],
	computed: {
		attrs() {
			return {
				class: 'image-panel pa-0 ma-0',
				style: `background-image: url(${this.src});`
			}
		}
	}
})
